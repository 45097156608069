import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Login from '../views/login/login.vue'
import Confirm from '../views/confirm/confirm.vue'
import Result from '../views/result/result.vue'
import Order from '../views/order/order.vue'
import LoginBorrow from '../views/loginborrow/loginborrow.vue'
import Borrow from '../views/borrow/borrow.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: Confirm
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
    path: '/loginborrow',
    name: 'loginborrow',
    component: LoginBorrow,
    meta: {
      ignoreAuth: true
    }
  },
  {
    path: '/borrow',
    name: 'borrow',
    component: Borrow
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log('to', to)
  const token = store.state.token
  if (to.name === 'home') {
    if (to.query.qrcode) {
      store.commit('qrcode', to.query.qrcode)
      store.commit('zhcode', '')
    }
    if (to.query.zhcode) {
      store.commit('zhcode', to.query.zhcode)
      store.commit('qrcode', '')
    }
  }
  if (to.query.AppleIdToken) {
    store.commit('appleIdToken', to.query.AppleIdToken)
  }
  if (to.matched.some(r => r.meta.ignoreAuth) || token) {
    next()
  } else {
    next({
      path: '/loginborrow'
    })
  }
})

export default router
