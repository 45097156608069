<template>
  <div>
    <div v-if="second > 0" class="result-loading-warp">
      <div class="result-loading circle">
        <circle-progress v-model="percent" />
        <span>{{ secondFmt }}s</span>
      </div>
      <div class="result-loading-tips">
        {{ i18n.msg_tips_loading }}
      </div>
    </div>
    <div v-else-if="order" class="result-content">
      <h2>{{ i18n.title }}</h2>
      <div class="tips">{{ i18n.tips }}</div>
      <img class="mainbanner" :src="imgSrc" alt="" />
      <div class="downloadbtn" @click="goOrder">
        <span>{{ i18nMsg.vieworder }}</span>
      </div>
      <div class="downloadbtn" @click="onDownload" style="margin-top: 24px">
        <span>{{ i18n.download }}</span>
      </div>
    </div>
    <result-msg
      v-else-if="loading"
      type="100"
      :text="i18n.msg_result_loading"
    />
    <result-msg v-else-if="error" type="500" :text="error" @click="onReload" />
    <result-msg v-else />
  </div>
</template>

<script>
import CircleProgress from "../../components/result/circle_progress.vue"
import ResultMsg from "../../components/result/msg.vue"
import { ORDER_CHECK } from "../../apis/cabinet.js"
import { PAYPAL_EXC_PAY } from "../../apis/payment.js"
import { mapState } from "vuex"
let loadingTimer
export default {
  components: {
    ResultMsg,
    CircleProgress
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    paypal(val, oldVal) {
      if (val && val.paymentId) {
        this.onPayExec()
      }
    }
  },
  computed: {
    ...mapState(["browser", "system", "scbOrder"]),
    i18n() {
      return this.$t("result")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    isError() {
      return this.second <= 0 && this.error
    },
    percent() {
      return (this.second % 30) * 3.334
    },
    secondFmt() {
      return (this.second + 0.45).toFixed(0)
    },
    imgSrc() {
      if (this.order && this.order.shebei && this.order.number) {
        return `https://m.pandanrg.com/content/cabinet${this.order.shebei}_${this.order.number}.gif`
      } else {
        return require("../../assets/imgs/result_main_banner.png")
      }
    }
  },
  data() {
    return {
      error: "",
      paypal: null,
      order: null,
      tradeNo: "",
      second: 0,
      loading: false,
      payType: "stripe"
    }
  },
  methods: {
    goRouter(path) {
      this.$router.push(path)
    },
    goOrder() {
      this.$router.replace("/order")
    },
    fail() {
      this.stopTimer()
      this.order = true
    },
    startTimer() {
      if (this.second === 0) {
        loadingTimer = setInterval(() => {
          this.second += 0.1
        }, 100)
      }
    },
    stopTimer() {
      clearInterval(loadingTimer)
      this.second = 0
    },
    onInput(val) {
      this.$emit("input", val)
    },
    onReload() {
      if (this.tradeNo) {
        this.checkOrder(this.tradeNo)
      } else {
        this.onPayExec()
      }
    },
    onDownload() {
      let downloadUrl = process.env.VUE_APP_GOOGLE_PLAY
      if (this.system === "ios") {
        downloadUrl = process.env.VUE_APP_APP_STORE
      }
      if (downloadUrl) {
        window.open(downloadUrl)
      } else {
        this.$toast(this.i18nMsg.appoffline)
      }
    },
    onPayExec() {
      this.startTimer()
      this.$post(
        PAYPAL_EXC_PAY,
        {
          ...this.paypal
        },
        (resp) => {
          this.error = ""
          const data = resp.data
          if (data.out_trade_no) {
            if (data.pay_type === "CZ") {
              this.$confirm({
                title: this.i18nMsg.title,
                content: this.i18nMsg.success_payment,
                confirmText: this.i18nMsg.confirm,
                showCancel: false
              }).then(() => {
                this.$router.push("/mine")
              })
            }
            if (data.pay_type === "YJ") {
              this.checkOrder(data.out_trade_no)
            }
          }
        },
        (error) => {
          if (this.second < 30) {
            this.onPayExec()
          } else {
            this.stopTimer()
            this.error = error
            console.log(error)
          }
        }
      )
    },
    checkOrder(tradeNo, payMode = "stripe") {
      this.startTimer()
      this.tradeNo = tradeNo
      this.loading = true
      this.$post(
        `${ORDER_CHECK}/${tradeNo}/${payMode}`,
        {},
        (resp) => {
          this.loading = false
          this.error = ""
          this.stopTimer()
          this.order = resp
        },
        (error) => {
          if (error === "正在请求中" || error === "Processing") {
            setTimeout(() => {
              this.checkOrder(tradeNo)
            }, 1000)
          } else {
            this.stopTimer()
            this.loading = false
            this.error = error
            console.log(error)
          }
        }
      )
    },
    onHome() {
      this.$router.push("/")
    }
  },
  created() {
    console.log(4)
    console.log("result route:", this.$route.query)
    console.log("url: ", window.location.href)
    let { tradeNo } = this.$route.query
    console.log("tradeNo: ", this.scbOrder)
    if (tradeNo) {
      this.checkOrder(tradeNo)
    }
  },
  beforeDestroy() {
    this.$store.commit("scbOrder", null)
  }
}
</script>

<style lang="less">
.result-content {
  // padding: 40px;
  min-height: 100vh;
  background: #fff;

  .unlocked {
    max-width: 30%;
    display: block;
    margin: 0px auto;
    padding: 50px 0 0;
  }

  h2 {
    font-size: 52px;
    font-family: "Montserrat-SemiBold";
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding: 120px 0 0;
  }

  .mainbanner {
    max-width: 50%;
    display: block;
    margin: 80px auto 0;
  }

  .tips {
    font-size: 28px;
    color: #666666;
    text-align: center;
    margin: 60px 40px 40px;
  }

  .downloadbtn {
    width: 80%;
    margin: 100px auto 0;
    background-color: #000;
    color: #fff;
    font-family: "DIN Condensed Bold";
    font-size: 60px;
    font-weight: bold;
    padding: 20px 0;
    text-align: center;
    border-radius: 20px;

    span {
      display: block;
      height: 56px;
    }
  }
}

.result-loading {
  &.circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-warp {
    background: #ffffff;
    padding: 30px 0;
  }

  & span {
    position: absolute;
    font-size: 70px;
    color: #f13453;
  }

  &-tips {
    text-align: center;
    font-size: 28px;
    color: #1bb6e6;
  }
}
</style>
