<template>
  <div class="container">
    <div>
      <div class="language">
        <span class="language-label" @click="showLangSelect">{{
          locale && locale.label
        }}</span>
        <span class="language-tip">LANGUAGE</span>
      </div>
      <img class="logo" src="../../assets/imgs/01-logo.png" alt="" />
    </div>
    <div class="home-body">
      <div v-if="!user" class="title">{{ i18n.powerbanktitle }}</div>
      <!-- <div v-if="!user" class="logingroup">
        <div class="logingroup-left">
          <Facebook v-if="!user" @success="loadUser(true)">
            <div class="facebookbtn">
              <img src="../../assets/imgs/icon_facebook.png" alt="">
              <span>{{ i18n.loginfb }}</span>
            </div>
          </Facebook>
        </div>
        <div class="options" @click="loginOptionShow = true">{{ i18n.otheropt }}</div>
      </div> -->
      <button v-if="!user" class="loginbtn" @click="onSendCode">
        <img
          :src="require('../../assets/imgs/icon_phone_selected.png')"
          alt=""
        />
        <span>{{ i18n.phone }}</span>
      </button>
      <button v-else class="loginbtn" @click="goRouter('/order')">
        <img
          :src="require('../../assets/imgs/icon_phone_selected.png')"
          alt=""
        />
        <span>{{ user && user.pname }}</span>
      </button>
      <!-- <div class="logoutoptions"  v-if="user" @click="onLogout">{{ i18n.logout }}</div> -->
      <div class="normaltitle">{{ i18n.selectmethod }}</div>
      <div v-if="payModeList.length > 0" class="paymethod">
        <template v-for="item in payModeList">
          <div
            class="paymethod-item"
            :class="{
              select: payMode && payMode.pPaymentType === item.pPaymentType
            }"
            v-if="
              item.phone === 'alipay'
                ? browser !== 'wechat'
                : item.phone === 'all' ||
                  item.phone === system ||
                  item.phone === browser ||
                  (item.phone === 'phone' && system !== '')
            "
            :key="item.pId"
            @click="payMode = item"
          >
            <img
              v-if="
                item.pPaymentType === 'GOOGLE_PAY' ||
                item.pPaymentType === 'STRIPE_GOOGLE'
              "
              :src="require('../../assets/imgs/icon_googlepay.png')"
              alt=""
            />
            <img
              v-else-if="
                item.pPaymentType === 'APPLE_PAY' ||
                item.pPaymentType === 'STRIPE_APPLE'
              "
              :src="require('../../assets/imgs/icon_applepay.png')"
              alt=""
            />
            <img
              v-else
              :src="require('../../assets/imgs/icon_card_Unselected.png')"
              alt=""
            />
            <span>{{ item.label }}</span>
          </div>
        </template>
      </div>
      <div v-else-if="!user || !cabinet" class="paymethod">
        <div
          class="paymethod-item"
          :class="{ select: payMode && payMode.pPaymentType === 'STRIPE_CARD' }"
          @click="payMode = { pPaymentType: 'STRIPE_CARD' }"
        >
          <img src="../../assets/imgs/icon_card_Unselected.png" alt="" />
          <span>{{ i18n.cCard }}</span>
        </div>
      </div>
      <div class="normaltitle">{{ i18n.paymentdetail }}</div>
      <div class="paymethod-detail">
        <div class="placeinput">
          <img class="preicon" src="../../assets/imgs/icon_pay.png" alt="" />
          <input
            type="text"
            readonly
            :placeholder="`${i18n.preauth} ${currencyName} ${
              (cabinet && cabinet.yajin) || 35
            }`"
          />
          <img
            class="afticon"
            src="../../assets/imgs/icon_detail_green.png"
            alt=""
            @click="preAuthorShow = true"
          />
        </div>
      </div>
      <!-- <div class="paymethod-detail">
        <div class="placeinput" @click="goRouter('/wallet')">
          <img class="preicon" src="../../assets/imgs/icon_pay.png" alt="">
          <input type="text" readonly :placeholder="`${i18n.balance} ${currencyName}${walletList[0] && walletList[0].pBalance || 0}`">
          <img class="afticon" src="../../assets/imgs/icon_more.png" alt="">
        </div>
      </div> -->
      <div class="shopdetail">
        <img src="../../assets/imgs/PANDA.png" alt="" />
        <div v-if="cabinet" class="centermsg">
          <p class="top">
            {{ currencyName
            }}{{ cabinet && cabinet.jifei && cabinet.jifei.toFixed(2) }}
            {{ i18n.perhour }}
          </p>
          <p class="bottom">
            {{ currencyName }}{{ cabinet.fengding }} {{ i18n.maxperday }}
          </p>
        </div>
        <div v-else class="centermsg">
          <p class="top">0.99 {{ currencyName }} / {{ i18n.perhour }}</p>
          <p class="bottom">2.99 {{ currencyName }} / {{ i18n.maxperday }}</p>
        </div>
        <div class="pricing" @click="showRentCostPop">
          {{ i18n.pricing }} <img src="../../assets/imgs/icon_morearrow.png" />
        </div>
      </div>
      <div class="coupon-code">
        <div class="placeinput">
          <img
            class="preicon"
            src="../../assets/imgs/icon_couponcode.png"
            alt=""
          />
          <input
            type="text"
            v-model="couponCode"
            :placeholder="i18n.enterpromocode"
          />
        </div>
      </div>
      <div class="rentbtn" @click="onRent">{{ i18n.rent }}</div>
      <div class="agreen">
        {{ i18n.tips }}
        <a
          @click="
            viewAgreen('https://m.pandanrg.com/content/privacy_policy.html')
          "
          >{{ i18n.privacy }}</a
        >
        {{ i18n.and }}
        <a
          @click="
            viewAgreen('https://m.pandanrg.com/content/user_agreement.html')
          "
          >{{ i18n.term }}</a
        >
      </div>
    </div>
    <div v-if="loginOptionShow" class="mask" @click="loginOptionShow = false">
      <div class="mask-main" @click.stop>
        <div class="appleline"></div>
        <!-- <div class="loginoptions">
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'phone' }" @click="currentLoginMode = 'phone'">
            <img src="../../assets/imgs/icon_phone_selected.png" alt="">
            <span>{{ i18n.phone }}</span>
          </div>
          <div class="loginoptions-item" :class="{ 'select': currentLoginMode === 'Community' }" @click="currentLoginMode = 'Community'">
            <span>{{ i18n.otheropt }}</span>
          </div>
        </div> -->
        <div class="phonelogin">
          <div class="area" @click="isAreaView = true">
            <span>+{{ areaCode }}</span>
            <div class="area-down"></div>
          </div>
          <input
            type="number"
            v-model="phoneNum"
            :placeholder="i18n.phonenumber"
          />
          <img
            src="../../assets/imgs/icon_next_circle.png"
            alt=""
            @click="onSendCode"
          />
        </div>
        <div class="agreen">
          {{ i18n.tips }}
          <a
            @click="
              viewAgreen('https://m.pandanrg.com/content/privacy_policy.html')
            "
            >{{ i18n.privacy }}</a
          >
          {{ i18n.and }}
          <a
            @click="
              viewAgreen('https://m.pandanrg.com/content/user_agreement.html')
            "
            >{{ i18n.term }}</a
          >
        </div>
      </div>
    </div>
    <popup-warp v-model="isCreditcard">
      <div>
        <div class="creditcard-input">
          <div id="credit-card-element"></div>
        </div>
        <div class="btn creditcard-btn" @click="onCreditcard">
          {{ i18n.savenow }}
        </div>
      </div>
    </popup-warp>
    <select-area v-model="isAreaView" @select="onSelct"></select-area>
    <PreAuthorTip
      v-if="preAuthorShow"
      @close="(res) => (preAuthorShow = res)"
    ></PreAuthorTip>
    <RentCostTip
      v-if="rentCostShow"
      :jifei="(cabinet && cabinet.jifei) || 0.99"
      :jifeiDanwei="(cabinet && cabinet.jifeiDanwei) || 60"
      :fengding="(cabinet && cabinet.fengding) || 2.99"
      :yajin="(cabinet && cabinet.yajin) || 35"
      :currency="currency"
      :currencyName="currencyName"
      @close="(res) => (rentCostShow = res)"
    ></RentCostTip>
    <LoginCode
      v-if="loginCodeShow"
      @close="(res) => (loginCodeShow = res)"
      @submit="onLogin"
    ></LoginCode>
  </div>
</template>

<script>
import md5 from "js-md5"
import { mapState, mapGetters } from "vuex"
import { getToken } from "../../utils/stripe_pay.js"
import stripeMixin from "../../mixins/stripe"
import { generateUUID, getMoble } from "../../utils/index"
import { baseURL } from "../../utils/http"
import { SelectArea } from "../../components/select"
import PreAuthorTip from "./component/preauthortip.vue"
import RentCostTip from "./component/rentcosttip.vue"
import LoginCode from "./component/logincode.vue"
import { PopupWarp } from "../../components/popup"
import { USER_INFO, LOGIN_CODE, SEND_CODE, USER_CAPTCHA } from "../../apis/user"
import { QR_CODE, ZH_CODE } from "../../apis/cabinet"
import {
  PAYMODE,
  STRIPE_PREAUTH_AUTO_CONFIRM,
  STRIPE_PREPAY_SPTOKEN,
  COUPON_SEND,
  SCB_RECHARGE,
  WALLET_LIST,
  STRIPE_PREAUTH_OF_CONFIRM,
  STRIPE_PAYORDER
} from "../../apis/payment"
export default {
  mixins: [stripeMixin],
  components: {
    SelectArea,
    PreAuthorTip,
    RentCostTip,
    LoginCode,
    PopupWarp
  },
  watch: {
    "payMode.pPaymentType": {
      handler(val, oldVal) {
        console.log("payMode", val)
        if (
          val === "APPLE_PAY" ||
          val === "STRIPE_APPLE" ||
          val === "STRIPE_GOOGLE" ||
          val === "GOOGLE_PAY"
        ) {
          if (!this.stripePayRequest && this.cabinet) {
            this.$loading(true)
            this.getStripeToken()
          }
        }
      },
      immediate: true, // 立即执行
      deep: true // 深度监听复杂类型内变化
    },
    cabinet(val) {
      if (val) {
        console.log("设备信息", val)
        this.getPayMode()
      }
    }
  },
  computed: {
    ...mapState([
      "browser",
      "user",
      "token",
      "cabinet",
      "system",
      "qrcode",
      "agentId",
      "loginMode",
      "phone"
    ]),
    ...mapGetters(["locale"]),
    scanUrl() {
      if (this.qrcode || this.$route.query.qrcode) {
        return `${QR_CODE}?id=${this.qrcode || this.$route.query.qrcode}`
      }
      if (this.zhcode || this.$route.query.zhcode) {
        return `${ZH_CODE}/${this.zhcode}`
      }
      return ""
    },
    i18n() {
      return this.$t("loginborrow")
    },
    i18nMsg() {
      return this.$t("msg")
    },
    currency() {
      return (this.cabinet && this.cabinet.currency) || "DOP"
    },
    currencyName() {
      return (this.cabinet && this.cabinet.currencyName) || "€"
    }
  },
  data() {
    return {
      isAreaView: false,
      areaCode: "33",
      captchaUrl: "",
      code: "",
      uuid: "",
      cardNum: "",
      phoneNum: "",
      couponCode: "",
      creditCardEmail: "",
      preAuthorShow: false,
      loginOptionShow: false,
      rentCostShow: false,
      loginCodeShow: false,
      isCreditcard: false,
      // payListShow: false, // 保存卡的卡列表是否显示
      currentLoginMode: "phone",
      payModeList: [],
      cardList: [],
      walletList: [],
      cardMode: null,
      payType: "YJ",
      outTradeNo: "",
      payMode: {
        pPaymentType: "STRIPE_CARD"
      },
      cardStyle: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      },
      stripePayRequest: null,
      creditcard: null,
      clientSecret: null,
      creditOrder: null,
      confirmType: "preAuth"
    }
  },
  methods: {
    showLangSelect() {
      this.$store.commit("isLangView", true)
    },
    onSelct(val) {
      this.areaCode = val
    },
    showRentCostPop() {
      this.rentCostShow = true
    },
    viewAgreen(url) {
      window.open(url)
    },
    onCaptcha() {
      this.code = ""
      this.uuid = generateUUID()
      this.captchaUrl = `${baseURL}${USER_CAPTCHA}?uuid=${this.uuid}`
    },
    goRouter(path) {
      this.$router.push(path)
    },
    onLogout() {
      console.log("退出==")
      this.$confirm({
        title: this.i18nMsg.title,
        content: this.i18nMsg.msg_logout,
        confirmText: this.i18nMsg.confirm,
        cancelText: this.i18nMsg.cancel
      }).then(() => {
        this.$store.commit("user", "")
        this.$store.commit("token", "")
        this.$store.commit("appleIdToken", "")
        // this.$store.commit('qrcode', '')
        this.payModeList = []
        this.cardList = []
        this.cardMode = null
      })
    },
    onRent() {
      console.log(this.qrcode)
      console.log(this.cabinet)
      if (this.user) {
        if (this.couponCode) {
          this.getCouponCode()
          return
        }
        this.onPay()
      } else {
        this.$toast(this.i18nMsg.unlogin)
        this.loginOptionShow = true
      }
    },
    onPay() {
      if (!this.user) {
        return
      }
      console.log(this.qrcode)
      // if (this.qrcode == "9527") {
      //   this.$toast(this.i18nMsg.oneOrdering)
      //   return
      // }
      if (!this.qrcode) {
        this.$toast(this.i18nMsg.nodevicecode)
        return
      }
      if (
        this.payMode.pPaymentType === "APPLE_PAY" ||
        this.payMode.pPaymentType === "STRIPE_APPLE" ||
        this.payMode.pPaymentType === "STRIPE_GOOGLE" ||
        this.payMode.pPaymentType === "GOOGLE_PAY"
      ) {
        this.stripePayRequest.show()
        return
      } else if (this.payMode.pPaymentType === "STRIPE_CARD") {
        this.onLoadElement()
        return
      }
      this.$loading(true)
      this.$post(
        `${SCB_RECHARGE}`,
        {
          paymentId: this.payMode.pId,
          callbackUrl: `https://m.pandanrg.com/#/result`,
          rechargeType: "balance",
          qrcode: this.qrcode || this.$route.query.qrcode,
          rentNow: true
        },
        (resp) => {
          this.$loading(false)
          console.log("scantoken===", resp)
          // this.creditOrder = resp.data
          this.$store.commit("scbOrder", resp.data)
          if (resp.data && resp.data.rentOrderId) {
            this.$router.replace(`/result?tradeNo=${resp.data.rentOrderId}`)
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: error,
            confirmText: this.i18nMsg.confirm,
            cancelText: this.i18nMsg.cancel
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8"
          }
        }
      )
    },

    onScan() {
      let url = this.scanUrl
      this.inputCodeShow = false
      this.$loading(true)
      this.$post(
        url,
        {},
        (resp) => {
          this.$loading(false)
          this.$store.commit("cabinet", {
            qrcode: this.code,
            ...resp
          })
        },
        (error) => {
          this.$loading(false)
          let errstr = ""
          if (error.includes("is offline") || error.includes("离线")) {
            errstr = this.$t("home.offline", { devicecode: this.qrcode })
          } else if (error.includes("Permission") || error.includes("权限")) {
            errstr = this.$t("home.permissionfail")
          } else if (error.includes("not bound") || error.includes("未绑定")) {
            errstr = this.$t("home.unbound", { devicecode: this.qrcode })
          } else if (error.includes("No battery") || error.includes("可用")) {
            errstr = this.$t("home.nobattery", { devicecode: this.qrcode })
          } else {
            errstr = error
          }
          this.$confirm({
            title: this.i18n.msg_fail_title,
            confirmText: this.i18nMsg.confirm,
            content: errstr,
            showCancel: false
          }).then(() => {
            this.$store.commit("qrcode", "")
          })
        }
      )
    },
    loadUser(isOnScan = false) {
      this.$get(
        USER_INFO,
        {},
        (resp) => {
          if (resp.user) {
            this.$store.commit("user", resp.user)
            this.loginOptionShow = false
            if (isOnScan && this.qrcode) {
              this.onScan()
            }
            this.getWalletist()
          } else {
            this.$confirm({
              title: this.i18n.msg_fail_title,
              content: this.i18n.msg_fail_user,
              showOk: false,
              cancelText: this.i18nMsg.cancel
            }).then(() => {
              this.onSendCode()
            })
          }
        },
        (error) => {
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18nMsg.userinfo_fail,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
          console.log(error)
        }
      )
    },
    getStripeToken() {
      getToken(this.cabinet && this.cabinet.yajin, this.onStripe)
        .then((data) => {
          console.log("stripe getToken", data)
          this.stripePayRequest = data
          this.$loading(false)
        })
        .catch((error) => {
          this.stripePayRequest = null
          console.log("stripe getTokenerr", error)
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_support,
            showCancel: false,
            confirmText: this.i18n.msg_fail_confirm
          })
        })
    },
    // apple和Google支付时调用
    onStripe(token, ev) {
      let url = ""
      let datas = {}
      if (this.cabinet) {
        url = STRIPE_PREPAY_SPTOKEN
        datas = {
          qrcode: this.cabinet.qrcode,
          stripeToken: token, // stripe官方支付的一次性token
          paymentId: this.payMode.pId // 代理或平台支付配置id
        }
      } else {
        this.$confirm({
          title: this.i18nMsg.title,
          content: this.i18nMsg.error,
          confirmText: this.i18nMsg.confirm,
          showCancel: false
        }).then(() => {
          this.$router.push("/")
        })
      }
      this.$post(
        url,
        datas,
        (resp) => {
          if (ev && ev.complete) {
            ev.complete("success")
          }
          this.$loading(false)
          console.log(resp)
          if (this.payType === "CZ") {
            this.$confirm({
              title: this.i18nMsg.title,
              content: this.i18nMsg.success_payment,
              confirmText: this.i18nMsg.confirm,
              showCancel: false
            }).then(() => {
              this.$router.replace("/mine")
            })
          } else {
            this.$toast(this.i18nMsg.success_payment)
            if (this.payType === "FK") {
              this.$router.replace(
                `/result?tradeNo=${resp.data.orderId}&payMode=stripe&payType=FK`
              )
              return
            }
            this.$router.replace(
              `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
            )
          }
        },
        (error) => {
          if (ev && ev.complete) {
            ev.complete("fail")
          }
          this.$loading(false)
          console.log(error)
          this.$confirm({
            title: this.i18nMsg.title,
            content: error,
            confirmText: this.i18nMsg.confirm,
            showCancel: false
          })
        }
      )
    },
    getCouponCode() {
      this.$loading(true)
      this.$get(
        `${COUPON_SEND}?code=${this.couponCode}`,
        {},
        (resp) => {
          this.$loading(false)
          console.log(resp)
          this.onPay()
        },
        (error) => {
          this.$loading(false)
          console.log(error)
          this.onPay()
        }
      )
    },
    getWalletist() {
      this.$loading(true)
      this.$get(
        WALLET_LIST,
        {
          agentId: this.agentId
        },
        (resp) => {
          this.$loading(false)
          console.log("Walletist", resp)
          this.walletList = resp.data
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    getPayMode() {
      this.$loading(true)
      this.$get(
        `${PAYMODE}?id=${this.cabinet.cabinetID}`,
        {},
        (resp) => {
          this.$loading(false)
          if (resp.data) {
            let list = resp.data
            list.map((v, i, arr) => {
              if (v.pPaymentType === "STRIPE_CARD") {
                v.label = this.i18n.cCard
                v.phone = "all"
              } else if (
                v.pPaymentType === "APPLE_PAY" ||
                v.pPaymentType === "STRIPE_APPLE"
              ) {
                v.label = "Apple Pay"
                v.phone = "ios"
              } else if (
                v.pPaymentType === "GOOGLE_PAY" ||
                v.pPaymentType === "STRIPE_GOOGLE"
              ) {
                v.label = "Google Pay"
                v.phone = "android"
              }
            })
            this.payModeList = list
            if (list.length > 0) {
              this.payMode = list[0]
              this.loadStripeScript(this.payMode)
            }
          }
        },
        (error) => {
          this.$loading(false)
          // this.$confirm({
          //   title: this.i18n.msg_fail_title,
          //   content: this.i18n.msg_fail_paymod,
          //   confirmText: this.i18n.msg_fail_retry,
          //   cancelText: this.i18n.msg_fail_cancel
          // }).then(() => {
          //   this.getPayMode()
          // })
          console.log(error)
        }
      )
    },
    onCreditcard() {
      let url = ""
      let datas = {}
      if (this.payType === "FK") {
        url = STRIPE_PAYORDER
        datas = {
          outTradeNo: this.outTradeNo,
          couponNo: null,
          paymentId: this.payMode.pId
        }
        this.confirmType = "czOrder"
      } else if (
        this.payType === "YJ" &&
        this.payMode &&
        this.payMode.pPaymentType === "STRIPE_CARD"
      ) {
        url = `${STRIPE_PREAUTH_OF_CONFIRM}/${this.cabinet.qrcode}?paymentId=${this.payMode.pId}&amount=${this.cabinet.yajin}`
        this.confirmType = "preAuth"
      } else {
        this.$toast(this.i18nMsg.payment_unsupported)
        return
      }
      this.$loading(true)
      this.$post(
        url,
        datas,
        (resp) => {
          this.$loading(false)
          console.log(resp)
          if (resp.data) {
            if (window.stripeElements) {
              this.clientSecret = resp.data
              this.onStripeSetup()
            } else {
              this.$toast("loading...")
            }
          }
        },
        (error) => {
          this.$loading(false)
          this.$confirm({
            title: this.i18n.msg_fail_title,
            content: this.i18n.msg_fail_order,
            confirmText: this.i18n.msg_fail_retry,
            cancelText: this.i18n.msg_fail_cancel
          }).then(() => {
            this.onCreditcard()
          })
          console.log(error)
        },
        {
          headers: {
            "Content-Type":
              this.payMode.pPaymentType === "STRIPE_CARD"
                ? "application/x-www-form-urlencoded"
                : "application/json;charset=UTF-8"
          }
        }
      )
    },
    onLoadElement() {
      if (window.stripeElements) {
        this.loadStripeScript(this.payMode)
        this.creditcard = window.stripeElements.create("card", {
          style: this.cardStyle
        })
        this.isCreditcard = true
        this.creditcard.mount("#credit-card-element")
      } else {
        this.$toast("loading...")
      }
    },
    onStripeSetup() {
      const vm = this
      vm.$loading(true)
      // paymentIntent通过付款方式确认卡设置
      window.stripe
        .confirmCardPayment(vm.clientSecret.clientSecret, {
          payment_method: {
            card: vm.creditcard,
            billing_details: {
              name: "test"
            }
          }
        })
        .then((result) => {
          console.log(result)
          vm.$loading(false)
          if (result.error) {
            vm.$toast(result.error.message)
          } else {
            vm.confirmSetup(
              result.paymentIntent.id,
              result.paymentIntent.payment_method
            )
          }
        })
    },
    confirmSetup(id, paymentMethod) {
      this.$loading(true)
      this.$get(
        // `${STRIPE_PREAUTH_AUTO_CONFIRM}`,
        `${STRIPE_PREAUTH_AUTO_CONFIRM}?tradeNo=${id}&orderType=${this.confirmType}&paymentMethod=${paymentMethod}`,
        {
          outTradeNo: this.clientSecret.outTradeNo
        },
        (resp) => {
          this.$loading(false)
          this.isCreditcard = false
          if (this.payType === "FK") {
            this.$router.replace(`/order`)
            return
          }
          this.$router.replace(
            `/result?tradeNo=${resp.data.orderId}&payMode=stripe`
          )
          console.log(resp)
        },
        (error) => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onLogin(code) {
      this.$loading(true)
      this.$post(
        LOGIN_CODE,
        {
          mobile: this.areaCode + this.phone,
          verifyCode: code
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          if (res.token) {
            this.$store.commit("token", res.token)
            this.onCode()
            this.loginCodeShow = false
            this.loginOptionShow = false
            this.$store.commit("loginMode", "phone")
          } else {
            this.$toast(this.i18n.msg_fail_login)
          }
        },
        (err) => {
          console.log(err)
          this.$loading(false)
        }
      )
    },
    onSendCode() {
      // if (!this.phone) {
      //   this.$toast(this.i18nMsg.msg_fail_phone)
      //   return
      // }
      let phone_num = this.phoneNum || this.phone
      if (!phone_num) {
        this.loginOptionShow = true
        return
      }
      let timp = new Date().valueOf()
      let str = timp.toString().replace(/0/g, "8")
      let mdsign = md5(str + "GZzbjkj")
      this.$loading(true)
      this.$post(
        SEND_CODE,
        {
          mobile: this.areaCode + phone_num,
          timestamp: timp,
          sign: mdsign,
          useDebug: true
        },
        (res) => {
          console.log(res)
          this.$loading(false)
          // this.loginCodeShow = true
          this.onLogin("1234")
          this.$store.commit("phone", phone_num)
        },
        (err) => {
          console.log(err)
          this.$loading(false)
          this.$toast(err)
        }
      )
    },
    onCode() {
      console.log(this.qrcode)
      if (this.token) {
        this.loadUser()
      }
      if ((this.qrcode || this.$route.query.qrcode) && this.token) {
        this.onScan()
      }
    }
  },
  created() {
    let qrCode = this.qrcode || this.$route.query.qrcode
    this.payType = this.$route.query.payType || "YJ"
    this.outTradeNo = this.$route.query.outTradeNo
    if (qrCode) {
      this.$store.commit("qrcode", qrCode)
      console.log(this.qrCode)
      // if (this.user) {
      //   this.onCode()
      // }
    }
    // 客户需要自动登录
    if (!this.user) {
      if (!this.phone) {
        this.$store.commit("phone", getMoble())
        this.onSendCode()
      } else {
        this.onSendCode()
      }
    }
    if (this.scanUrl) {
      this.onScan()
    }
  },
  mounted() {}
}
</script>

<style lang="less" scoped>
.buttonDiv /deep/ .S9gUrf-YoZ4jf {
  iframe {
    width: 100% !important;
    margin: 0 auto !important;
  }
}

.buttonDiv {
  position: relative;
  z-index: 99;
  top: 0;
  opacity: 0;
}

.language {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-label {
    display: block;
    border: solid 1px #fff;
    padding: 10px;
    font-size: 29px;
    border-radius: 10px;
    text-align: center;
    color: #fff;
  }

  &-tip {
    font-size: 13px;
    margin-top: 10px;
    color: #fff;
  }
}

.googlewarp {
  position: absolute;
  top: 0;
  z-index: 90;
  width: 100%;
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.select {
  color: #fff !important;
  background-color: #000 !important;
  border: none !important;
}

.selectd {
  color: #fff;
  border: solid 1px #000 !important;
}

.mb-8 {
  margin-bottom: 60px;
}

.minheight-5 {
  min-height: 90px;
}

.agreen {
  width: 80%;
  color: #6e6e6e;
  text-align: center;
  margin: 40px auto 20px;
  font-size: small;

  a {
    text-decoration: none;
    color: #3c4043;
    font-weight: 500;
  }
}

.loginbtn {
  border: solid 1px #ebebeb;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  width: 100%;
  background: none;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.addcard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 20px;
  border: solid 1px #ebebeb;
  color: #757575;

  // box-shadow: 3px 6px 10px #e8e8e8;
  .left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      margin-right: 20px;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   height: 100vh;
  background: #000;

  .logo {
    width: 150px;
    display: block;
    margin: 20px 0;
  }
}

.home-body {
  background-color: #fff;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  overflow: hidden;
  padding: 40px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .title {
    width: 100%;
    font-family: "Montserrat-SemiBold";
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    // white-space: nowrap;
    margin: 0 0 50px;
  }

  .options {
    width: 47%;
    margin: 10px auto 0;
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    border-radius: 20px;
    padding: 20px 0;
    background: none;
  }

  .logoutoptions {
    width: 100%;
    font-family: "Montserrat-Medium";
    text-align: center;
    color: #c8c8c8;
    margin: 40px 0;
    background: none;
  }

  .normaltitle {
    font-family: "Montserrat-Medium";
    font-size: 28px;
    color: #171917;
    margin: 30px 0 32px;
  }

  .paymethod {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .paymethod-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 45px;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin-right: 30px;
    margin-top: 20px;

    img {
      width: 45px;
      height: 40px;
      margin-right: 10px;
    }

    span {
      // color: #000;
      font-size: 28px;
    }
  }

  .placeinput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: solid 1px #ebebeb;
    border-radius: 20px;
    padding: 20px;
    margin: 40px 0 0;

    .preicon {
      width: 38px;
      height: 38px;
      margin-right: 20px;
    }

    .afticon {
      width: 36px;
      height: 36px;
      margin-left: 20px;
    }

    .cvc {
      font-size: 28px;
      color: #c8c8c8;
    }

    input {
      flex: 1;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
    }
  }

  .shopdetail {
    border: dashed 4px #000;
    border-radius: 20px;
    padding: 10px;
    margin: 50px 0 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    & > img {
      width: 90px;
      height: 142px;
      position: absolute;
      left: 20px;
      top: -30px;
    }

    .centermsg {
      p {
        margin: 10px 0;
      }

      .top {
        font-size: 28px;
        color: #171917;
      }

      .bottom {
        color: #666666;
        font-size: 24px;
      }
    }

    .pricing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 40px;
      color: #000;

      img {
        width: 40px;
        height: 40px;
        margin-left: 10px;
      }
    }
  }

  .rentbtn {
    width: 100%;
    background: #000;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: "Montserrat-SemiBold";
    font-size: 36px;
    font-weight: 500;
    max-height: 108px;
    margin: 40px 0 0;
  }

  .mt-4 {
    margin-top: 40px;
  }
}

.facebookbtn {
  border: solid 5px #dadce0;
  color: #3c4043;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 20px 0;
  margin-bottom: 40px;
  font-size: 28px;

  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;

  .mask-main {
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px;
  }

  .appleline {
    background-color: #ebebeb;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto 40px;
  }

  .loginoptions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin: 60px 0;

    &-item {
      border: solid 1px #ebebeb;
      border-radius: 20px;
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      margin: 10px 25px 0 0;

      img {
        width: 30px;
        height: 40px;
        margin-right: 10px;
      }
    }
  }

  .phonelogin {
    border: solid 1px #ebebeb;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin-bottom: 40px;

    img {
      width: 60px;
      height: 60px;
    }

    input {
      flex: 1;
      font-size: medium;
      border: none;
      outline: none;
    }

    .captcha {
      width: 180px;
    }

    .area {
      width: 100px;
      padding: 0 10px;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .area-down {
      // margin-left: 10px;
      width: 0;
      height: 0;
      border-width: 10px 10px 0;
      border-style: solid;
      border-color: black transparent transparent;
    }
  }

  .facebookbtn {
    border: solid 5px #dadce0;
    color: #3c4043;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .goolebtn {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: solid 1px #ebebeb;
    color: #000;
    width: 100%;
    padding: 20px 0;
    margin-bottom: 40px;
    font-size: 28px;

    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
  }
}

.creditcard-input {
  margin: 48px 48px 48px;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #e4e7ed;
}

.creditcard-email {
  margin: 48px;
  box-sizing: border-box;

  input {
    width: calc(100% - 20px);
    border: 1px solid #e4e7ed;
    border-radius: 20px;
    outline: none;
    padding: 20px 0 20px 20px;
  }
}

.creditcard-btn {
  margin: 48px;
}

.addsign {
  float: left;
  background-color: #fff;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px;
  width: 18%;
  height: 100%;
  border-radius: 20px;
  margin: 33px 0 20px 20px;

  span {
    display: block;
    background-color: #dddddd;
    color: #fff;
    font-size: 85px;
    text-align: center;
    border-radius: 20px;
  }
}

.scroll-x {
  float: right;
  width: 70%;
  overflow-x: scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px;
}

.popup-card-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 3px 6px 10px #e8e8e8;
  padding: 10px 20px;
  margin: 20px 20px 20px 0;

  p {
    margin: 0;
  }

  .value {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: #00904a;
    font-weight: 500;

    .pBrand {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  .deleteicon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
</style>
