<template>
  <div class="mask" @click="onClose()">
    <div class="mask-main" @click.stop>
      <div class="appleline"></div>
      <h2>{{ i18n.rentcosttitle }}</h2>
      <div class="feedetail">
        <p>{{ currencyName }}{{ jifei && jifei.toFixed(2) }} / {{ jifeiDanwei }} {{i18n.min}}</p>
        <p>{{ currencyName }}{{ fengding }} / {{ i18nlo.maxperday }}</p>
        <p>{{ i18n.free }}</p>

      </div>
      <!-- <div v-else class="feedetail">
        <p>AU $3.30 per 60 min</p>
        <p>AU $15 max.per day</p>
      </div> -->
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_warn.png" alt="">
        <p>{{ i18n.tip1.format({ currencyName, yajin: yajin }) }}</p>
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_timing.png" alt="">
        <p>{{ i18n.tip2 }}</p>
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_countdown.png" alt="">
        <!-- <div> -->
        <p>{{ i18n.tip3 }}</p>
        <!-- <p>{{ i18n.tip3_1.format({cyajin: cyajin}) }}</p>
          <p>{{ i18n.tip3_2 }}</p>
          <p>{{ i18n.tip3_3 }}</p> -->
        <!-- </div> -->
      </div>
      <div class="renttip-item">
        <img src="../../../assets/imgs/icon_gift.png" alt="">
        <p>{{ i18n.tip4 }}</p>
      </div>
      <div class="times">
        <div class="times-item">
          <span class="num">{{ days }}</span>
          <span class="label">{{ i18n.Days }}</span>
        </div>
        <div class="times-item">
          <span class="num">{{ hours }}</span>
          <span class="label">{{ i18n.Hours }}</span>
        </div>
        <div class="times-item">
          <span class="num">{{ minutes }}</span>
          <span class="label">{{ i18n.Minutes }}</span>
        </div>
      </div>
      <div class="myslider">
        <VanSlider v-model="value" :min="0" :step="1" :max="maxMinutes" active-color="#000" inactive-color="#EBEBEB"
          bar-height="12px" @input="sliderInput">
          <template #button>
            <div class="custom-button"></div>
          </template>
        </VanSlider>
      </div>
      <div class="totalmoney">{{ i18n.charged }} {{ currencyName }}{{ money }}</div>
    </div>
  </div>
</template>

<script>
import { Slider } from 'vant'
import { mapState } from 'vuex'
export default {
  components: {
    VanSlider: Slider
  },
  props: {
    jifei: {
      type: Number,
      default: 3.30
    },
    jifeiDanwei: {
      type: Number,
      default: 60
    },
    fengding: {
      type: Number,
      default: 15
    },
    yajin: {
      type: Number,
      default: 55
    },
    cyajin: {
      type: Number,
      default: 1
    },
    currency: {
      type: String,
      default: 'DOP'
    },
    currencyName: {
      type: String,
      default: 'RD$'
    }
  },
  watch: {
    value(val) {
      // let danwei = this.jifeiDanwei > 30 ? 60 : 30
      let base = Math.floor(val / 60)
      // console.log('days: ', Math.floor(base / 24), 'hours: ', Math.floor(base % 24), 'min: ', Math.floor(val % 60))
      let days = Math.floor(base / 24)
      let hours = Math.floor(base % 24)
      let minutes = Math.floor(val % 60)
      this.days = days
      this.hours = hours
      this.minutes = minutes
      if (days >= 1 && days < 2 && minutes >= 0) {
        this.money = (this.fengding * 2).toFixed(2)
        // this.money = 30.00
      } else if (days >= 2 && minutes >= 0) {
        this.money = 55.00
      } else if (hours >= 1 && hours < 2 && minutes >= 0) {
        this.money = (this.jifei * 2).toFixed(2)
        // this.money = 6.60
      } else if (hours >= 2 && hours < 3 && minutes >= 0) {
        this.money = (this.jifei * 3).toFixed(2)
        // this.money = 9.90
      } else if (hours >= 3 && hours < 4 && minutes >= 0) {
        this.money = (this.jifei * 4).toFixed(2)
        // this.money = 13.20
      } else if (hours >= 4 && hours < 24 && minutes >= 0) {
        this.money = this.fengding
        // this.money = 15.00
      } else if (minutes > 0 && minutes <= 15) {
        this.money = 1.00
      } else if (days === 0 && hours === 0 && minutes === 0) {
        this.money = 0
      } else {
        this.money = this.jifei
        // this.money = 3.30
      }
    }
  },
  computed: {
    ...mapState([
      'user',
      'token',
      'cabinet'
    ]),
    i18n() {
      return this.$t('rentcost')
    },
    i18nlo() {
      return this.$t('loginborrow')
    },
    i18nMsg() {
      return this.$t('msg')
    }
  },
  data() {
    return {
      value: 0,
      maxMinutes: 2 * 48 * 30,
      minutes: 0,
      hours: 0,
      days: 0,
      money: 0
    }
  },
  methods: {
    sliderInput(value) {
      // console.log(value)
      this.value = value
    },
    onClose() {
      this.$emit('close', false)
    }
  },
  created() {
  },
  mounted() {
  }
}
</script>

<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(53, 53, 53, 0.5);
  z-index: 100;

  .mask-main {
    position: absolute;
    bottom: 0;
    overflow: auto;
    width: 100%;
    height: 80%;
    box-sizing: border-box;
    background: #fff;
    border-top-right-radius: 100px;
    border-top-left-radius: 100px;
    padding: 40px 40px 75px;
  }

  .appleline {
    background-color: #EBEBEB;
    width: 30%;
    height: 8px;
    border-radius: 100px;
    margin: 0 auto;
  }

  h2 {
    text-align: center;
    font-size: 36px;
    white-space: nowrap;
    font-family: 'Montserrat-SemiBold';
    font-weight: bold;
    color: #171917;
    margin: 40px 0;
  }

  .feedetail {
    width: 60%;
    margin: 0 auto;
    border: dashed 6px #171917;
    border-radius: 20px;
    color: #000;
    font-size: 32px;
    font-family: 'Montserrat-SemiBold';
    font-weight: bold;
    background: rgba(0, 252, 0, 0.08);
    padding: 10px 20px;

    p {
      margin: 10px 0;
      text-align: center;
    }
  }

  .renttip-item {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: dashed 2px #5b5b5b;
    padding: 20px 0;
    font-family: 'Montserrat-Medium';

    img {
      width: 48px;
      height: 48px;
      margin-right: 20px;
      margin-top: 20px;
    }

    p {
      color: #666666;
      font-size: 24px;
      margin: 20px 0;
    }
  }

  .renttip-item:nth-child(7) {
    border: none;
  }

  .times {
    margin: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Montserrat-SemiBold';

    &-item {
      width: 32%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .num {
        color: #000;
        font-size: 44px;
        font-weight: bold;
      }

      .label {
        font-weight: bold;
        font-size: 28px;
        color: #171917;
        margin-top: 10px;
      }
    }
  }

  .myslider {
    width: 80%;
    margin: 0 auto;

    .custom-button {
      width: 32px;
      height: 32px;
      background-color: #000;
      border: solid 8px #000;
      border-radius: 100px;
    }
  }

  .totalmoney {
    text-align: center;
    font-size: 32px;
    font-family: 'Montserrat-SemiBold';
    color: #000;
    font-weight: bold;
    margin: 60px 0 0;
  }
}
</style>
