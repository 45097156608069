export default {
  home: {
    minute: "minute",
    price_info:
      "{mianfei} minutes free, maximum per day  {currencyName}{fengding}, deposit {currencyName}{yajin},  free rental deposit",
    submit: "Recharge",
    borrow: "Borrow",
    msg_rentcount: "You currently have {zujieNum} orders that are not closed",
    cancel: "Cancel",
    confirm: "Confirm",
    msg_fail_title: "Error",
    msg_fail_content: "Rental failed, please try again",
    offline:
      "The device {devicecode} is offline, please contact the staff present",
    permissionfail: "Permission denied.",
    unbound: "Mix QR code {devicecode} is not bound to the device",
    nobattery: "No battery available in device {devicecode}",
    next: "NEXT",
    renttext: "Rent a power bank."
  },
  loginborrow: {
    cCard: 'Credit Card',
    perhour: "hour",
    maxperday: "day",
    preauth: "Pre-authorisation",
    enterpromocode: "Enter promo code",
    powerbanktitle: "Sign in to unlock your power bank",
    msg_fail_title: "Error",
    msg_fail_user: "Error getting user information. Do you want to try again?",
    msg_rentcount: "You currently have {zujieNum} orders that are not closed",
    msg_fail_support: "The system is not supported",
    msg_fail_confirm: "Confirm",
    msg_fail_retry: "Retry",
    msg_fail_cancel: "Cancel",
    msg_fail_order: "Failed to create lease order",
    msg_fail_paymod: "Failed to obtain payment method",
    msg_fail_login: "Login failed",
    Apple: "Apple",
    otheropt: "Other options",
    selectmethod: "Select your payment method",
    paymentdetail: "Payment method details",
    couponcode: "Coupon code",
    rent: "Confirm Payment",
    pricing: "See pricing",
    phone: "Phone",
    Google: "Google",
    Facebook: "Facebook",
    loginfb: "Sign in with Facebook",
    logingg: "Sign in with Google",
    captcha_code: "Code",
    phonenumber: "Enter your phone number",
    msg_fail_code: "Please enter the verification code",
    msg_fail_phone: "Please enter your phone number",
    logout: "Exit account",
    savenow: "Save Now",
    delete: "Delete",
    addcard: "Enter card details",
    msg_fail_content: "Rental failed, please try again",
    title: "Please confirm authorisation",
    policy: "Policy.",
    term: " Terms and Conditions",
    and: "and",
    privacy: "Privacy Policy.",
    tips: "By continuing you agree to our ",
    cancel: "CANCEL",
    agree: "AGREE",
    balance: "Balance"
  },
  preauth: {
    preauthortitle: "About the pre-authorisation",
    tip1:
      "When starting a rental, a pre-authorisation is placed on your payment method. This is just to ensure there are sufficient funds available to pay for your rental.",
    tip2:
      "Your bank may send you a notification but it is not a charge: only the price of your rental will be charged.",
    tip3:
      "Depending on your bank, it can take a few hours up to a few days for the amount to be adjusted on your statements, this is normal."
  },
  rentcost: {
    free: "Free minutes 3min",
    per: "per",
    min: "min",
    charged: "You will be charged",
    rentcosttitle: "How much does the rental cost?",
    tip1:
      "If you do not return the power bank within 48 hours you will be charged a non-return fee of {currencyName} {yajin} .",
    tip2: "Duration is rounded to the nearest half hour (30 or 60 minutes).",
    tip3: "One day rental is 24 hours from the time the power bank is hired.",
    tip3_1:
      "A pre-authorisation fee of {currencyName} {cyajin} will be placed on your payment method. This is to ensure there are sufficient funds available to pay for your rental.",
    tip3_2:
      "Your bank may send you a notification but it is not a charge; only the price of your rental will be charged.",
    tip3_3:
      "Depending on your bank, it can take a few hours, up to a few days for the amount to be adjusted on your statements.",
    tip4: "Some venues might offer some free hours or discounts. Enjoy!",
    Days: "Days",
    Hours: "Hours",
    Minutes: "Minutes"
  },
  logincode: {
    login: "Login",
    retry: "Resend",
    title: "Verification code",
    subtitle: "Please enter the verification code we sent to your phone number"
  },
  agreenconfirm: {
    title: "Please confirm authorisation",
    policy: "Policy.",
    term: "PowerBox's Terms & Conditions",
    and: "and",
    privacy: "Privacy Policy.",
    tips: "l agree to ",
    cancel: "CANCEL",
    agree: "AGREE"
  },
  login: {
    phonenumber: "Phone Number",
    verificationcode: "Verification Code",
    Rentpowerbank: "Rent a power bank",
    Rentpowerbanktip: "Enter your cell phone number to get started",
    phone_number: "Please enter an {0}-digit mobile number ",
    captcha_code: "Code",
    send_code: "Send Code",
    login: "Login",
    agree: "By clicking Login, you agree to ",
    readme: `User Agreement`,
    msg_fail_login: "Login failed",
    msg_success_login: "Login successful",
    msg_success_login_code:
      "Successfully received, please download the APP to log in to use",
    msg_fail_user: "Error getting user information. Do you want to try again?",
    no_sms: "Did not receive text message",
    retry: "Resend",
    receive: "Apply",
    invite_pwd: "Invitation code",
    vip_agreen_tip: "By continuing, you are indicating that you accept our",
    vip_agreen_service: "Terms of Service",
    vip_agreen_and: "and",
    vip_agreen_policy: "Privacy Policy"
  },
  confirm: {
    title: "Payment Confirmation",
    pay_label: "Amount",
    addcard: "Add Wallet Card",
    price_tips:
      "Click to confirm payment, indicating that you have read and agreed to the ",
    price_btn: "《Value-added Agreement》",
    pay_type: "Please select your preferred payment method",
    submit: "Confirm lease",
    charge: "Confirm recharge",
    msg_fail_title: "Warning",
    msg_fail_order: "Failed to create lease order",
    msg_fail_confirm: "Confirm",
    msg_fail_retry: "Retry",
    msg_fail_cancel: "Cancel"
  },
  mine: {
    title: "User Profile",
    wallet: "My Wallet",
    balance: "My Balance",
    mywalletcard: "My Card",
    addcard: "Add Wallet Card",
    deposit: "Deposit",
    wallet_in: "Charge",
    wallet_out: "Withdraw",
    wallet_log: "Wallet Log",
    order: "Order",
    login_tips: "Please Login",
    msg_fail_title: "Error",
    msg_fail_user: "Error getting user information. Do you want to try again?",
    help: "Help",
    setting: "Setting",
    logout: "Logout",
    unpaid: "Unpaid",
    using: "In Use"
  },
  msg: {
    oneOrdering:
      "1 order is in progress, and the lease cannot be continued for the time being.",
    nopaymode: "Payment method acquisition failed",
    unlogin: "You haven't logged in yet, please login",
    msg_fail_title: "Warning",
    deletecard: "Are you sure to delete this card?",
    loading: "Loading...",
    title: "Tips",
    success: "Successful operation",
    error: "Unknown error,please try again later.",
    userinfo_fail: "User information invalid, please login again",
    roleerror: "Insufficient permissions, please consult the administrator",
    neterror: "Network connection failed. Please try again later",
    confirm: "Confirm",
    retry: "Retry",
    cancel: "Cancel",
    msg_logout: "Are you sure to exit the account?",
    vieworder: "View order",
    addorselectcard: "Please add or select a card package to continue",
    success_payment: "Payment completed",
    fail_payment: "Payment failure",
    keyword: "keyword",
    unpaid_order: "There are unpaid orders",
    go_pay: "Go pay",
    no_more: "no more",
    noshop: "No merchants",
    msg_fail_code: "Please enter the verification code",
    msg_fail_phone: "Please enter your phone number",
    msg_fail_orderid: "Please enter the order number",
    msg_fail_email: "Email cannot be empty",
    exceedorder:
      "You already have an order under lease, please return it before continuing",
    nodevicecode: "Device code does not exist",
    appoffline: "The app has not been launched yet, please wait",
    payment_unsupported: "This payment method is not supported",
    msg_fail_balance: "Insufficient user balance",
    msg_fail_order_balance: "Order does not exist or balance is insufficient"
  },
  result: {
    title: "Power bank unlocked!",
    tips: "Your power bank is now ready,so you can charge on the go!",
    download: "Download",
    msg_result_loading: "The payment result is being confirmed...",
    msg_tips_loading:
      "Portable battery is popping up, please wait patiently...",
    haveorder: "Official order has been generated"
  },
  result_msg: {
    loading: "Loading...",
    refresh: "Refresh"
  },
  order: {
    title: "Lease Order",
    in_use: "In use",
    ended: "Ended",
    msg_error: "Request failed",
    no_data: "No Data"
  },
  order_item: {
    order_number: "No.",
    venue_rented: "Venue rented",
    time_rented: "Time rented",
    venue_returned: "Venue returned",
    time_returned: "Time returned",
    charge_standard: "Charge standard",
    rent_occurred: "Rent occurred",
    returned: "Returned",
    unreturned: "Unreturned",
    type_0: "Not Completed",
    type_1: "Renting",
    type_2: "Canceled",
    type_3: "Returned",
    type_4: "Fault occurred",
    type_5: "Overtime",
    min: "Min",
    unpaid: "Unpaid",
    payment: "Payment"
  }
};
