<template>
  <page :back="true" :right="true">
    <result-msg v-if="!items || items.length === 0" :type="msgtype" :text="i18n.no_data" @click="loadList(1)"></result-msg>
    <div v-else class="order-warp">
      <order-item v-for="(item, i) in borrowItems" :key="i" :item="item" @loadlist="reflash"></order-item>
      <order-item v-for="(item, i) in finishItems" :key="i" :item="item" :payTypeValue="payTypeValue" @onpay="onPay" @loadlist="reflash"></order-item>
    </div>
  </page>
</template>

<script>
import { ORDER_LIST } from '../../apis/user'
import { KSHER_RECHARGE_CONFIRM } from '../../apis/payment.js'
import OrderItem from '../../components/order/item'
import ResultMsg from '../../components/result/msg'
import { mapState } from 'vuex'
export default {
  components: {
    OrderItem,
    ResultMsg
  },
  computed: {
    ...mapState(['headLogo']),
    i18n () {
      return this.$t('order')
    },
    i18nMsg () {
      return this.$t('msg')
    },
    borrowItems () {
      return this.items.filter(item => {
        return item.pJiesuanid === 0 && (item.pZujie === 1 || item.pZujie === 4)
      })
    },
    finishItems () {
      return this.items.filter(item => {
        return (item.pZujie !== 1 && item.pZujie !== 4) || item.pJiesuanid === 1
      })
    }
  },
  data () {
    return {
      items: [],
      error: '',
      msgtype: '100',
      pPrice: 0,
      payTypeValue: 'AlipayCN',
      payTypeComponent: null
    }
  },
  methods: {
    reflash () {
      this.loadList()
    },
    changeRadio (v) {
      console.log('radio', v.index, v.value)
      this.payTypeValue = v.value
    },
    onPay (v) {
      console.log('v', v)
      this.payTypeComponent = v.children
      this.pPrice = v.price
    },
    goPay (v) {
      this.payTypeValue = v
      this.payTypeComponent.onSubmit()
    },
    loadList (page = 1) {
      this.$loading(true)
      this.$get(
        ORDER_LIST,
        {
          page,
          pageSize: 10
        },
        resp => {
          this.$loading(false)
          if (resp.page) {
            this.error = ''
            this.items = resp.page.records
            this.msgtype = '410'
          } else {
            this.error = this.i18n.msg_error
            this.msgtype = '500'
          }
        },
        error => {
          this.$loading(false)
          this.error = error
          this.msgtype = '500'
        }
      )
    },
    onKSherPayConfirm (outTradeNo) {
      let url = `${KSHER_RECHARGE_CONFIRM}?outTradeNo=${outTradeNo}`
      this.$loading(true)
      this.$get(
        url,
        {},
        resp => {
          this.$loading(false)
          console.log('onKSherPayConfirm', resp)
          // if (resp.data) {
          //   this.checkOrder(resp.data.orderId)
          // }
        },
        error => {
          this.$loading(false)
          console.log(error)
        }
      )
    },
    onHome () {
      this.$router.push('/')
    }
  },
  created () {
    this.$loading(true)
    setTimeout(() => {
      this.loadList()
    }, 1000)
    let { payType, outTradeNo } = this.$route.query
    if (payType === 'KsherPay') {
      this.onKSherPayConfirm(outTradeNo)
    }
  }
}
</script>

<style lang="less">
.order-warp {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 100%;
  // height: calc(100% + 45px);
  overflow-y: scroll;
  padding: 48px;
}
</style>
