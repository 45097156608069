export const USER_CAPTCHA = 'captcha.webp'
export const LOGIN_CODE = 'saas/mobileLogin'
// export const SEND_CODE = 'sendSms'
export const SEND_CODE = 'saas/sendVerifyCode'
export const USER_INFO = 'userInfo'
export const GETAUTHCODEURL = 'getMPayOauthCodeUrl'
export const LOGIN_INVITE = 'loginFromInvite'

export const LOGIN_ALIPAY = 'loginAlipayByCode' // 支付宝登录
export const LOGIN_MPAY = 'login/mPayOauthCode' // mPay登录
export const LOGIN_WECHAT = 'loginWechatByUserInfo' // 微信登录
export const LOGIN_FACEBOOK = 'saas/loginFacebookByToken' // FACEBOOK登录
export const LOGIN_GOOGLE = 'saas/loginGoogleByCode' // GOOGLE登录
export const LOGIN_APPLE = 'loginAppleByToken' // GOOGLE登录

export const JS_CONFIG = 'cdb/setting/js_config'
export const MPAY_CONFIG = '/sign/mPay'

export const ORDER_LIST = 'cdb/mine/order/list'

export const HELP_LIST = 'cdb/mine/help/list'

// export const TX_APPLY = 'cdb/mine/tixian/apply'  /app/cdb/mine/applyForRefund/multiCurrency
export const TX_APPLY = 'cdb/mine/applyForRefund/multiCurrency/new'
export const TX_POST = 'cdb/mine/tixian/create'

export const LOGIN_FIREBASE = 'loginFirebaseByUserInfo'

export const OEDER_COUNT = 'cdb/mine/order/count'

export const COUPON_RECORD = 'cdb/mine/coupon/record'
export const COUPON_SEND = 'cdb/mine/coupon/send'
export const COUPON_LIST = 'cdb/mine/coupon/list'
export const LOGO_LIST = 'logoUrl' // 获取应用头部logo
export const FEEDBACK = 'cdb/mine/feedback' // 合作加入
