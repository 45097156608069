export default {
  home: {
    minute: "minute",
    price_info: "{mianfei} minutes gratuites, maximum par jour {currencyName}{fengding}, caution {currencyName}{yajin}, caution de location gratuite",
    submit: "Recharger",
    borrow: "Emprunter",
    msg_rentcount: "Vous avez actuellement {zujieNum} commandes qui ne sont pas clôturées",
    cancel: "Annuler",
    confirm: "Confirmer",
    msg_fail_title: "Erreur",
    msg_fail_content: "La location a échoué, veuillez réessayer",
    offline: "L'appareil {devicecode} est hors ligne, veuillez contacter le personnel présent",
    permissionfail: "Permission refusée.",
    unbound: "Le code QR mix {devicecode} n'est pas lié à l'appareil",
    nobattery: "Aucune batterie disponible dans l'appareil {devicecode}",
    next: "SUIVANT",
    renttext: "Louez, chargez, profitez!"
  },
  loginborrow: {
    cCard: 'Carte de crédit',
    perhour: 'heure',
    maxperday: 'jour',
    preauth: "Pré-autorisation",
    enterpromocode: "Saisir le code promotionnel",
    powerbanktitle: "Connectez-vous pour déverrouiller votre batterie externe",
    msg_fail_title: "Erreur",
    msg_fail_user: "Erreur lors de l'obtention des informations utilisateur. Voulez-vous réessayer ?",
    msg_rentcount: "Vous avez actuellement {zujieNum} commandes qui ne sont pas clôturées",
    msg_fail_support: "Le système n'est pas supporté",
    msg_fail_confirm: "Confirmer",
    msg_fail_retry: "Recommencez",
    msg_fail_cancel: "Annuler",
    msg_fail_order: "Échec de la création de la commande de location",
    msg_fail_paymod: "Échec de l'obtention du mode de paiement",
    msg_fail_login: "La connexion a échoué",
    Apple: "Pomme",
    otheropt: "Autres options",
    selectmethod: "Sélectionnez votre méthode de paiement",
    paymentdetail: "Détails du mode de paiement",
    couponcode: "Code promo",
    rent: "Confirmer le paiement",
    pricing: "Voir tarifs",
    phone: "Téléphone",
    Google: "Google",
    Facebook: "Facebook",
    loginfb: "Connectez-vous avec Facebook",
    logingg: "Connectez-vous avec Google",
    captcha_code: "Code",
    phonenumber: "Entrez votre numéro de téléphone",
    msg_fail_code: "Entrez le code de vérification S'il vous plait",
    msg_fail_phone: "Veuillez entrer votre numéro de téléphone",
    logout: "Quitter le compte",
    savenow: "Sauvegarder maintenant",
    delete: "Supprimer",
    addcard: "Entrez les détails de la carte",
    msg_fail_content: "La location a échoué, veuillez réessayer",
    title: "Veuillez confirmer l'autorisation",
    policy: "Politique.",
    term: " Termes et conditions",
    and: "et",
    privacy: "Politique de confidentialité.",
    tips: "En continuant, vous acceptez notre ",
    cancel: "ANNULER",
    agree: "ACCEPTER",
    balance: "Équilibre"
  },
  preauth: {
    preauthortitle: "A propos de la pré-autorisation",
    tip1: "Lors du démarrage d'une location, une pré-autorisation est effectuée sur votre moyen de paiement. Ceci sert simplement à garantir que vous disposez de fonds suffisants pour payer votre location.",
    tip2: "Votre banque peut vous envoyer une notification mais ce n'est pas un prélèvement : seul le prix de votre location vous sera facturé.",
    tip3: "En fonction de votre banque, cela peut prendre quelques heures jusqu'à quelques jours pour que le montant soit ajusté sur vos relevés, c'est normal."
  },
  rentcost: {
    free: '3 min minutes gratuites',
    per: 'par',
    min: 'min',
    charged: 'Vous serez facturé',
    rentcosttitle: "Combien coûte la location ?",
    tip1: "Si vous ne rendez pas la batterie dans les 48 heures, des frais de non-retour de {yajin}{currencyName} vous seront facturés.",
    tip2: "La durée est arrondie à la demi-heure la plus proche (30 ou 60 minutes).",
    tip3: "Une journée de location correspond à 24 heures à compter de la location de la batterie.",
    tip3_1: "Des frais de préautorisation de {currencyName} {cyajin} seront appliqués à votre mode de paiement. Ceci afin de garantir qu'il y a suffisamment de fonds disponibles pour payer votre location.",
    tip3_2: "Votre banque peut vous envoyer une notification mais ce n'est pas une charge ; seul le prix de votre location vous sera facturé.",
    tip3_3: "En fonction de votre banque, l'ajustement du montant sur vos relevés peut prendre quelques heures, voire quelques jours.",
    tip4: "Certains lieux peuvent offrir des heures gratuites ou des réductions. Profitez-en !",
    Days: "Jours",
    Hours: "Heures",
    Minutes: "Minutes"
  },
  logincode: {
    login: "Se connecter",
    retry: "Renvoyer",
    title: "Le code de vérification",
    subtitle: "Veuillez saisir le code de vérification que nous avons envoyé à votre numéro de téléphone"
  },
  agreenconfirm: {
    title: "Veuillez confirmer l'autorisation",
    policy: "Politique.",
    term: "Conditions générales de PowerBox",
    and: "et",
    privacy: "Politique de confidentialité.",
    tips: "je suis d'accord",
    cancel: "ANNULER",
    agree: "ACCEPTER"
  },
  login: {
    phonenumber: "Numéro de téléphone",
    verificationcode: "Le code de vérification",
    Rentpowerbank: "Louer une batterie externe",
    Rentpowerbanktip: "Entrez votre numéro de téléphone portable pour commencer",
    phone_number: "Veuillez saisir un numéro de mobile à {0} chiffres",
    captcha_code: "Code",
    send_code: "Envoyer le code",
    login: "Se connecter",
    agree: "En cliquant sur Connexion, vous acceptez ",
    readme: `User Agreement`,
    msg_fail_login: "La connexion a échoué",
    msg_success_login: "Connexion réussie",
    msg_success_login_code: "Reçu avec succès, veuillez télécharger l'application pour vous connecter et l'utiliser",
    msg_fail_user: "Erreur lors de l'obtention des informations utilisateur. Voulez-vous réessayer ?",
    no_sms: "Je n'ai pas reçu de SMS",
    retry: "Renvoyer",
    receive: "Appliquer",
    invite_pwd: "Code d'invitation",
    vip_agreen_tip: "En continuant, vous indiquez que vous acceptez nos",
    vip_agreen_service: "Conditions d'utilisation",
    vip_agreen_and: "et",
    vip_agreen_policy: "Politique de confidentialité"
  },
  confirm: {
    title: "Confirmation de paiement",
    pay_label: "Montant",
    addcard: "Ajouter une carte portefeuille",
    price_tips: "Cliquez pour confirmer le paiement, indiquant que vous avez lu et accepté le ",
    price_btn: "《Accord à valeur ajoutée》",
    pay_type: "Veuillez sélectionner votre mode de paiement préféré",
    submit: "Confirmer le bail",
    charge: "Confirmer la recharge",
    msg_fail_title: "Avertissement",
    msg_fail_order: "Échec de la création de la commande de location",
    msg_fail_confirm: "Confirmer",
    msg_fail_retry: "Recommencez",
    msg_fail_cancel: "Annuler"
  },
  mine: {
    title: "Profil de l'utilisateur",
    wallet: "Mon portefeuille",
    balance: "Mon solde",
    mywalletcard: "Ma carte",
    addcard: "Ajouter une carte portefeuille",
    deposit: "Dépôt",
    wallet_in: "Charge",
    wallet_out: "Retirer",
    wallet_log: "Journal du portefeuille",
    order: "Commande",
    login_tips: "Veuillez vous connecter",
    msg_fail_title: "Erreur",
    msg_fail_user: "Erreur lors de l'obtention des informations utilisateur. Voulez-vous réessayer ?",
    help: "Aide",
    setting: "Paramètre",
    logout: "Se déconnecter",
    unpaid: "Non payé",
    using: "Utilisé"
  },
  msg: {
    oneOrdering: "1 commande est en cours, et la location ne peut être poursuivie pour le moment.",
    nopaymode: "Échec de l'acquisition du mode de paiement",
    unlogin: "Vous n'êtes pas encore connecté, veuillez vous connecter",
    msg_fail_title: "Avertissement",
    deletecard: "Etes-vous sûr de supprimer cette carte ?",
    loading: "Chargement...",
    title: "Conseils",
    success: "Opération réussie",
    error: "Erreur inconnue, veuillez réessayer plus tard.",
    userinfo_fail: "Informations utilisateur invalides, veuillez vous reconnecter",
    roleerror: "Autorisations insuffisantes, veuillez consulter l'administrateur",
    neterror: "La connexion réseau a échoué. Veuillez réessayer plus tard",
    confirm: "Confirmer",
    retry: "Recommencez",
    cancel: "Annuler",
    msg_logout: "Etes-vous sûr de quitter le compte ?",
    vieworder: "Voir",
    addorselectcard: "Veuillez ajouter ou sélectionner un paquet de cartes pour continuer",
    success_payment: "Paiement terminé",
    fail_payment: "Échec de paiement",
    keyword: "mot-clé",
    unpaid_order: "Il y a des commandes impayées",
    go_pay: "Va payer",
    no_more: "pas plus",
    noshop: "Aucun commerçant",
    msg_fail_code: "Entrez le code de vérification S'il vous plait",
    msg_fail_phone: "Veuillez entrer votre numéro de téléphone",
    msg_fail_orderid: "Veuillez saisir le numéro de commande",
    msg_fail_email: "L'e-mail ne peut pas être vide",
    exceedorder: "Vous avez déjà une commande en location, merci de la retourner avant de continuer",
    nodevicecode: "Le code de l'appareil n'existe pas",
    appoffline: "L'application n'a pas encore été lancée, veuillez patienter",
    payment_unsupported: "Ce mode de paiement n'est pas pris en charge",
    msg_fail_balance: "Solde utilisateur insuffisant",
    msg_fail_order_balance: "La commande n'existe pas ou le solde est insuffisant"
  },
  result: {
    title: "Batterie déverrouillée !",
    tips: "Votre batterie est maintenant prête, vous pouvez la récupérer !",
    download: "Télécharger",
    msg_result_loading: "Le résultat du paiement est en cours de confirmation...",
    msg_tips_loading: "La batterie du portable apparaît, veuillez patienter...",
    haveorder: "La commande officielle a été générée"
  },
  result_msg: {
    loading: "Chargement...",
    refresh: "Rafraîchir"
  },
  order: {
    title: "Ordre de location",
    in_use: "Utilisé",
    ended: "Terminé",
    msg_error: "Demande échoué",
    no_data: "Pas de données"
  },
  order_item: {
    order_number: "n.",
    venue_rented: "Lieu loué",
    time_rented: "Temps loué",
    venue_returned: "Lieu retourné",
    time_returned: "Le temps est revenu",
    charge_standard: "Norme de facturation",
    rent_occurred: "Loyer survenu",
    returned: "Revenu",
    unreturned: "Non retourné",
    type_0: "Pas achevé",
    type_1: "En location",
    type_2: "Annulé",
    type_3: "Rendu",
    type_4: "Un défaut s'est produit",
    type_5: "Overtime",
    min: "Min",
    unpaid: "Non payé",
    payment: "Paiement"
  }
};
