<template>
  <div id="app">
    <router-view />
    <!-- <VanActionSheet v-model="langView" :actions="actions" @select="onSelect" /> -->
    <SelectLang v-model="langView"></SelectLang>
    <PopupLoading v-if="loading"></PopupLoading>
  </div>
</template>

<script>
import { PopupLoading } from './components/popup'
import { mapState } from 'vuex'
import { ActionSheet } from 'vant'
import { SelectLang } from './components/select'
export default {
  components: {
    PopupLoading,
    SelectLang,
    // VanActionSheet: ActionSheet
  },
  computed: {
    ...mapState(['loading', 'isLangView', 'localeArr', 'lang']),
    langView: {
      get() {
        return this.isLangView
      },
      set(val) {
        console.log('langview==', val)
        this.$store.commit('isLangView', val)
      }
    }
  },
  data() {
    return {
      actions: []
    }
  },
  methods: {
    onSelect(val) {
      console.log('选择====', val)
      this.$i18n.locale = val.value
      this.$store.commit('lang', val.value)
      this.langView = false
    }
  },
  created() {
    let arr = []
    this.localeArr.map(v => {
      let obj = {
        name: v.label,
        value: v.value,
        disabled: false
      }
      arr.push(obj)
    })
    if (!this.$store.state.lang || this.$store.state.lang == 'de-DE') {
      this.$store.commit("lang", "de_DE");
    }

    this.actions = arr
  }
}
</script>

<style lang="less">
html,
body {
  background: #F0F1F3;
  padding: 0;
  margin: 0;
  height: 100%;
  color: #333333;
  font-family: 'Montserrat-Medium' !important;
}

#app {
  font-size: 28px;
}

.btn {
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #000;
  font-size: 32px;
  border-radius: 20px;
}

.w-full {
  width: 100%;
}

.home-logo {
  width: 150px;
}

@font-face {
  font-family: "DIN Condensed Bold"; //自定义字体名称
  src: url('fonts/DIN\ Condensed\ Bold.ttf'); //字体包路径
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium"; //自定义字体名称
  src: url('fonts/Montserrat-Medium.ttf'); //字体包路径
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-SemiBold"; //自定义字体名称
  src: url('fonts/Montserrat-SemiBold.ttf'); //字体包路径
  font-weight: bold;
  font-style: normal;
}
</style>
