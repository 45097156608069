<template>
  <span class="popup-toast" v-if="msg">{{ msg }}</span>
</template>

<script>
export default {
  data() {
    return {
      msg: "",
      config: {
        duration: 2000
      },
      toastTime: null
    }
  },
  methods: {
    show(msg) {
      this.msg = msg
      console.log(this.msg)
      if (this.toastTime) {
        clearTimeout(this.toastTime)
      }
      this.toastTime = setTimeout(() => {
        this.msg = ""
      }, this.config.duration)
    }
  },
  mounted() {
    // this.show()
  }
}
</script>

<style lang="less" scoped>
.popup-toast {
  padding: 14px 28px;
  color: #fff;
  font-size: 28px;
  background-color: rgba(1, 1, 1, 0.5);
  border-radius: 10px;
  max-width: 500px;
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
